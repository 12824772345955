@import './src/styles/colors';

.item-detail {
  &__count {
    display: flex;
    padding: 10px;
    background: $gray;
    justify-content: space-between;
    font-size: 18px;
  }
  &__photo > span{
      width: 100%;
    display: block;
  }
  &__photo > span > div.ant-upload.ant-upload-select {
    display: block;
  }
  &__breakdown-checkbox {
    border: 2px solid $gray;
    padding: 11px;
    .ant-checkbox-wrapper {
      font-size: 18px;
    }
  }
}