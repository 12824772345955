@import './src/styles/colors';
.moving-type {


  h1 {
    text-align: center;
  }
  &__single {
    background: $gray;
    padding: 1.3em;
    text-align: left;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    border-bottom: 1px solid #e9e8e8;
    display: flex;
    align-items: center;
  }
  .active {
    border: 2px solid #1890ff;
  }
  &__icon {
    margin-right: 20px;
    img {
      width: 30px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
  }
}