.started {
  text-align: center;
  .ant-space {
    width: 100%;
  }
  &__box-icon {
    font-size: 75px;
    display: block;
    color: #ddd;
  }
  &__button {
    width: 100%;
  }
  .draft {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }
    &__card {
      width: 48%;
    }
    &__image {
      display: flex;
      height: 100px;
      width: 100%;
      justify-content: center;
      align-items: center;
      background: #ddd;
      position: relative;
      img  {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__badge {
      position: absolute;
      background: #222;
      top: 0;
      right: 0;
      color: #fff;
      padding: 7px 10px;
    }
  }
}