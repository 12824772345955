@import './src/styles/colors';

.items-box {
  margin-bottom: 10px;
  border-radius: 3px;
  &__header {
    background: #33302f;
    display: flex;
    color: #fff;
    font-size: 20px;
    align-items: center;
  }
  &__count {
    padding: 15px 18px;
    background: #080808;
    line-height: 1;
    margin-right: 15px;
  }
  &__list {
    background: $gray;
    &__empty {
      padding: 40px 20px;
      text-align: center;
      font-size: 20px;
    }
  }
  .single-stuff {
    display: flex;
    border-bottom: 2px solid #fff;
    font-weight: 500;
    align-items: center;
    font-size: 18px;
    justify-content: space-between;
    padding-right: 13px;
    &-number {
      padding: 15px 18px;
      margin-right: 15px;
    }
    &-name {
      width: 100%;
    }
    &-box {
      display: flex;
      align-items: center;
      font-weight: 100;
      text-decoration: underline;
      color: #000;
      font-size: 16px;
      a {
        color: #000;
      }
    }
    &-button {
      margin: 0 5px;
      cursor: pointer;
    }
  }
}