@import '~antd/dist/antd.css';

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-background {
  background: #fff;
  padding: 40px;
  margin: 0;
  min-height: calc(100vh - 112px);
}
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
h1.ant-typography {
  text-align: center;
}
main.ant-layout-content.site-layout-background.container > div {
  width: 500px;
}
.cursor-pointer {
  cursor: pointer;
}